export default {
  shop_center_txt: "Торговый центр",
  score_txt: "Очки",
  minute_txt: "минута",
  wallet_txt: "кошелек",
  today_txt: "Сегодня",
  week_txt: "на этой неделе",
  month_txt: "в этом месяце",
  year_txt: "весь год",
  main_indicators_txt: "Основные индикаторы",
  sale_price: "цена продажи",
  expected_profit: "ожидаемая прибыль",
  order_count: "Количество заказа",
  goods_click_count: "Количество кликов по товару",
  supplier_txt: "Поставщик",
  friends_help_txt: "Друзья помогают",
  feedback_txt: "Отзыв",
  feedback: "Обратная связь",
  online_service_txt_1: "Служба поддержки клиентов по депозитам",
  online_service_txt_2: "Деловая консультация",
  q_and_a_txt: "Вопрос и ответ",
  open_now_txt: "Открыть",
  certification_activation_code: "Код активации сертификации",
  plz_write_invite_code: "Пожалуйста, введите код приглашения",
  time_txt: "время",
  times_txt: "время",

  wallet_total_assets: "Общая сумма активов",
  balance_txt: "Баланс",
  pending_settlement_txt: "ожидающее урегулирование",
  recharge_txt: "Пополнение",
  withdraw_txt: "Вывести",
  fund_details_txt: "Детали фонда",
  no_more_txt: "Больше нет",
  no_data_txt: "Данных пока нет",
  Detail_txt: "Подробности",
  num_txt: "Номер",
  money_txt: "сумма",
  remark_txt: "Примечания",
  no_remark_txt: "Нет замечаний",
  tip_txt: "совет",
  tip_content_txt:
    "В настоящее время из-за проблем с валютой, подписанных продавцами в разных странах",
  need_contact_service: "Необходимо обратиться в службу поддержки",
  contact_service: "Обратиться в службу поддержки клиентов",

  feedback_title: "Оставьте свое мнение",
  feedback_placeholder: "В целях безопасности не указывайте личную информацию",
  submit_txt: "Отправить",
  sure_txt: "ОК",
  submit_suc_txt: "Отправка успешна",

  loading: "Загрузка...",

  newer_action: "Активность новичков",
  newer_traffic_package: "Пакет поддержки трафика для новичков",
  newer_get_millions_of_products: "Получите миллионы товаров",
  newer_get: "получено",
  newer_receive: "получить",
  newer_action_ruler: "Правила активности для новичков",
  newer_receive_suc: "Успешно получено",

  help_friends_help: "Друзья помогают",
  help_your_friends_stores: "Помочь вашим друзьям",
  get_store_product_heating: "Получить нагрев товаров в магазине",
  help_friends_txt: "Помочь друзьям",
  help_friends_activity_rules: "Правила активности помощи друзьям",
  assistance_details: "Детали помощи",
  team_list: "Список команды",
  help_friend_name: "Помочь имя друга",
  plz_input: "Пожалуйста, введите",
  plz_input_friend_name: "Пожалуйста, введите имя вашего друга, чтобы помочь",
  help_suc: "Помогите успешно",

  online_service: "Онлайн-обслуживание клиентов",
  online_24_hour_service: "Круглосуточная онлайн-поддержка",
  customer_service: "обслуживание клиентов",
  copy_and_add_id: "Скопировать и добавить идентификатор ЛИНИИ",
  plz_input_question_detail: "Пожалуйста, введите детали вашего вопроса",
  harry_reply_your: "Мы ответим вам как можно скорее",
  click_copy: "Нажмите, чтобы скопировать",
  copy_suc: "Копирование выполнено успешно",
  copy_fail: "Не удалось скопировать",

  overall: "в целом",
  cooperate: "сотрудничать",
  cancel: "отменить",
  supplier_num: "Номер поставщика",
  no_use_supplier: "Нет доступного поставщика",
  here_add_supplier: "Добавьте сюда нового поставщика",
  add_supplier: "Добавить поставщика",
  plz_input_supplier_num: "Пожалуйста, введите номер поставщика",
  add_suc: "Добавление успешно",
  remove_suc: "Удален успешно",

  new_products_launched: "Выпущены новые продукты",
  profit_txt: "Прибыль",
  has_been_added: "Уже на полках",
  on_display: "на дисплее",
  coming_soon_txt: "Доступно сейчас",
  sold_txt: "Продано",
  removed_txt: "Удалено",

  order_detail_txt: "Детали заказа",
  waiting_express_delivery: "Ожидание экспресс-доставки",
  customer_info: "Информация о клиенте",
  order_good: "Заказать товары",
  order_num: "Номер заказа",
  order_date: "дата заказа",
  order_content: "Сводка заказа",
  purchase_price_txt: "Цена покупки",
  income_txt: "Доход",

  order_txt: "Заказ",
  pending_payment: "ожидающий платеж",
  ready_for_shipment: "Готов к отправке",
  shipped_txt: "отправлено",
  paid_txt: "Доставлено",
  good_num: "номер товара",
  pay_and_ship: "Оплата и отгрузка",
  pay_suc: "Платеж успешен",
  no_order: "Пока нет заказа",
  no_order_tips: "Здесь вы можете отслеживать свои заказы и возвраты",
  suer_order: "Подтвердить платеж",
  pay_way: "способ оплаты",
  wallet_pay: "платеж через кошелек",
  sure_pay: "Подтвердить платеж",
  paying_txt: "Платит...",

  manage_showcase: "Управление витриной",
  add_new_good: "Добавить новый товар",
  heating_txt: "Отопление",
  promotion: "продвижение",
  promotion_tip: "Совет по продвижению",
  promotion_tip_txt:
    "Ваше текущее количество баллов меньше 200, и вы не можете платить за свои продукты",
  copy_link: "Копировать ссылку",
  scan_qr_code: "Сканировать QR-код",

  about_good: "О товаре",
  specification: "спецификация",
  product_overview: "Обзор продукта",

  top_tips_1: "Преобразуйте онлайн-магазин Douyin",
  top_tips_2: "В настоящее время поддерживаются только внутренние приглашения",
  active_place_holeder: "Пожалуйста, введите",

  promotion_01: "продвижение",
  promotion_02: "Сумма акции",
  promotion_03: "Пожалуйста, введите сумму акции",
  promotion_04: "Подтвердить продвижение",
  promotion_05: "Продвижение успешно",

  bao_txt: "Экспозиция",
  bal_no: "Недостаточно баланса",

  wallet_type_01: "Пополнение системы вручную",
  wallet_type_02: "Вывод средств вручную",
  Wallet_type_03: "Платеж",
  wallet_type_04: "Системный вычет",
  wallet_type_05: "доход от прибыли",
  wallet_type_06: "Вычет платежа",
  wallet_type_07: "Потребление при распределении",
  wallet_type_08: "Доход от рекламы",
  wallet_type_09: "Увеличение вознаграждений",
  wallet_type_10: "Зарегистрируйтесь бесплатно",
  wallet_type_11: "Награда за оборот",
  wallet_type_12: "Вывод средств отклонен",

  all_orders: "Все заказы",
  mine_orders: "Мои заказы",
  mine_services: "Мои услуги",
  _tzz: "Прыжки...",

  info_01: "Имя",
  info_02: "Электронная почта",
  info_03: "Контактная информация",
  info_04: "Пол",
  info_05: "Конфиденциально",
  info_06: "Мужской",
  info_07: "Женский",
  info_08: "Дата рождения(yyyy-MM-dd)",
  info_09: "Страна",
  info_10: "Город",
  info_11: "Номер карты",
  info_12: "Тип карты",
  info_13: "Эмитент карты",
  info_14: "Срок годности(yyyy-MM-dd)",
  info_15: "CVV-код",
  info_16: "Пароль безопасности",
  info_17: "Повторите пароль безопасности",
  info_18: "Пожалуйста, введите пароль еще раз",
  info_19: "Два пароля безопасности несовместимы",
  info_20: "Удостоверение личности/паспорт",
  info_21: "Выберите тип изображения для загрузки",
  info_22: "Удостоверение личности (спереди и сзади)",
  info_23: "Паспорт",
  info_24: "Водительское удостоверение",
  info_25: "Вид на жительство",
  info_26: "Справка о состоянии здоровья",
  info_27: "Пожалуйста, загрузите удостоверение личности",
  info_28:
    "Пожалуйста, свяжитесь со службой поддержки клиентов, чтобы проверить банковскую информацию для оплаты.",

  wl_withdraw_money: "Сумма вывода",
  wl_withdraw_code_201:
    "Записи о снятии средств находятся на рассмотрении, пожалуйста, не отправляйте их повторно",
  wl_withdraw_code_202: "Информация о банковской карте неверна",
  wl_withdraw_code_400: "Исключение записи о снятии средств",
  wl_withdraw_code_204: "Неверный пароль безопасности",
  wl_withdraw_code_205: "Остаток маржи не может быть выведен",
  wl_withdraw_code_207:
    "Звездный рейтинг текущего пользователя не может быть отозван, пожалуйста, увеличьте звездный рейтинг",
  wl_withdraw_code_208:
    "Звездный рейтинг текущего пользователя достиг максимального количества выводов средств сегодня",

  wl_withdraw_order_01: "Все",
  wl_withdraw_order_02: "Не проверено",
  wl_withdraw_order_03: "Проверено",
  wl_withdraw_order_04: "Вывод средств не выполнен",
  wl_withdraw_order_05: "Число",
  wl_withdraw_order_06: "Сумма вывода",
  wl_withdraw_order_07: "Метод вывода средств",
  wl_withdraw_order_08: "Информация о снятии средств",
  wl_withdraw_order_09: "На рассмотрении",
  wl_withdraw_order_10: "Одобрено",
  wl_withdraw_order_11: "Завершено",
  wl_withdraw_order_12: "Отменено",
  wl_withdraw_order_13: "заморожено",

  hl_mine_invite_code: "Мой код приглашения",
  hl_help_friend_invite_code: "Помочь коду приглашения друга",
  hl_certification_activation_code: "код приглашения",
  hl_plz_write_invite_code: "Пожалуйста, введите код приглашения",
  hl_help_code_0: "Код приглашения не может быть пустым",
  hl_help_code_201: "Пользователь привязал код приглашения",
  hl_help_code_202: "Код приглашения неправильный",
  hl_help_code_203: "Код приглашения не может быть привязан к вам",
  gl_visits: "Посещения",

  _in_sp: "Рейтинг продукта",
  _r_k01: "Рейтинг",
  _r_k02: "Товар",
  _r_k03: "жара",

  _zw_ph: "Информации о рейтинге продукта пока нет! ",
  _zf_201: "Ненормальный статус заказа",
  _zf_202: "Недостаточный баланс активов",
  _zf_203: "Ошибка платежа",

  _withdraw_order_txt: "Снять ордер",

  shop_num: "номер магазина",
  plz_input_shop_num: "Пожалуйста, введите номер магазина",
  shop_num_error: "Неверный номер магазина",
  supplier_num_error: "Номер поставщика неверен",
  supplier_num_has_bound: "Номер поставщика привязан",

  sc_day_sale: "Ежедневные продажи",
  sc_week_sale: "еженедельные продажи",
  hl_mine_shop: "Мой магазин",
  hl_shop_name: "название магазина",
  hl_input_shop_name: "Пожалуйста, введите название магазина",
  hl_add_shop_name: "Добавить название магазина",
  hl_update_shop_name: "Изменить название магазина",
  hl_update_suc: "Модификация прошла успешно",

  hl_rabate_01: "Комиссия нижнего уровня",
  hl_rabate_02: "Запись о скидках",
  hl_rabate_03: "Коэффициент скидки",
  hl_rabate_04: "Общая сумма комиссии",
  hl_rabate_05: "Общий объем продаж",
  hl_rabate_06: "Объем продаж",
  hl_rabate_07: "Сумма скидки",
  hl_rabate_08: "Время регистрации",
  hl_rabate_09: "Помощь 1-го уровня",
  hl_rabate_10: "Помощь 2-го уровня",
  hl_rabate_11: "Помощь 3-го уровня",

  dd_order_01: "Отменить заказ",
  dd_order_02: "Возврат",
  dd_order_03: "Возвращение",
  dd_order_04: "Возвращено",

  _t_p_qr: "Подтвердить",
  _t_p_txt01:
    "Стоит ли отменить заказ? Отмена заказа безвозвратна и не найдена!",
  _t_p_txt02: "Пожалуйста, подтвердите, нужно ли вернуть этот заказ! ",
  _t_p_txt03: "Отмена прошла успешно",
  _t_p_txt04: "Запрос на возврат отправлен",

  showcase_01: "Разместить заказ",
  showcase_02: "Заказ успешно размещен",
  showcase_03: "Пожалуйста, введите идентификатор друга",

  goods_price: "цена",

  u_pwd_tips:
    "Пожалуйста, обратитесь в службу поддержки клиентов, чтобы изменить пароль безопасности",

  g_to_be_purchased: "будет куплен",
  g_purchasing_and_shipping: "Покупка и доставка",
  g_suer_purchase_order: "Подтвердить покупку",

  hl_star_rating_text_1: "Торговец с одной звездой",
  hl_star_rating_text_2: "Двухзвездочный торговец",
  hl_star_rating_text_3: "Самсунг Торговец",
  hl_star_rating_text_4: "Четырехзвездочный торговец",
  hl_star_rating_text_5: "Пятизвездочный торговец",

  hl_auth_tips:
    "Аутентификация настоящего имени отправлена ​​и находится на рассмотрении...",

  ml_01: "Непрочитано",
  ml_02: "Читать",
  ml_03: "Содержимое сообщения...",
  ml_04: "Отправить",
  ml_05: "Загрузка...",

  gl_sale_price: "цена продажи",

  wd_fee: "плата за обработку",
  wd_receipt: "Фактическое прибытие",
  wd_receipt_way: "Выберите существующий способ оплаты",
  wd_bank_info: "Банковская информация",
  wd_bank_info_tip: "Формат: Имя-Название банка-Счет-Номер банковской карты",
  wd_success: "Вывод средств успешно",
  wd_success_tip:
    "Ваша заявка на вывод средств была успешной и ожидается, что она поступит на ваш счет в течение 24 часов.",

  g_removed: "Удалено",
  g_sale_ing: "Распродажа",

  sd_01: "Рекомендуемые продукты",
  sd_02: "Eще",
  sd_03: "Рекомендуемый магазин",
  sd_04: "Текущий продукт",
  sd_05: "Oдин",
  sd_06: "Просмотреть магазин",
  sd_07: "Товары магазина",
  sd_08: "Магазин активности",
  sd_09: "Нелегальный магазин",

  mc_01: "Подписан",
  mc_02: "Оплачено",
  mc_03: "Время подписи",
  mc_04: "Время оплаты",
  mc_05: "Номер заказа",
  mc_06: "Подпись",
  mc_07: "Покупатель",
  mc_08: "Адрес",
  mc_09: "Регион",
  mc_10: "Связаться с покупателем",

  lgi_01: "Информация о логистике",
  lgi_02: "Соответствовать международному складу товаров",
  lgi_03: "Идет экспресс-доставка",
  lgi_04: "В пути",
  lgi_05: "Доставка",

  np_01: "Предупреждение о риске",
  np_02: "Системное объявление",
  np_03: "Я прочитал и понял",
  np_04: "Новости",

  sl_01: "Листинг в один клик",

  w_pwd_info_01: "Пароль для вывода средств",
  w_pwd_info_02: "Повторите пароль для вывода",
  w_pwd_info_03: "Пожалуйста, введите пароль для вывода еще раз",
  w_pwd_info_04: "Два пароля для вывода несовместимы",
  w_pwd_info_05: "Неверный пароль для вывода средств",
  w_pwd_info_06:
    "Пожалуйста, свяжитесь со службой поддержки, чтобы изменить пароль для вывода средств",

  l_g_01: "Номер мобильного телефона/адрес электронной почты",
  l_g_02:
    "Пожалуйста, введите номер вашего мобильного телефона/адрес электронной почты",
  l_g_03: "Код подтверждения",
  l_g_04: "Пожалуйста, введите проверочный код",
  l_g_05: "Отправить",
  l_g_06: "Пароль для входа",
  l_g_07: "Пожалуйста, введите пароль для входа",
  l_g_08: "Повторите пароль для входа",
  l_g_09: "Пожалуйста, введите пароль для входа еще раз",
  l_g_10: "Зарегистрироваться",
  l_g_11: "Войти",
  l_g_12: "Учетная запись уже есть, войдите",
  l_g_13: "Перейти к регистрации",
  l_g_14: "Выйти",
  l_g_15: "Подтвердить выход?",
  l_g_16: "Язык",
  l_g_17: "Два пароля для входа несовместимы",
  l_g_18:
    "Информация о пользователе не существует, проверьте, правильно ли введен идентификатор~",
  l_g_19: "Имя пользователя",
  l_g_20: "Пожалуйста, введите имя пользователя",
  l_g_21: "Войти и скопировать",

  h_c_01: "Прибыль от заказа",
  h_c_02: "Оплачено",
  h_c_03: "Подлежит отправке",
  h_c_04: "Подтверждено",
  h_c_05: "Продажи",
  h_c_06: "Пригласительный код для открытия магазина",
  h_c_07: "Пожалуйста, введите код приглашения в магазин",
  h_c_08: "Пригласить друзей",

  g_c_01: "Моя коллекция",
  g_c_02: "Сбор успешен",
};

export default {
  shop_center_txt: "ショップセンター",
  score_txt: "ポイント",
  minute_txt: "分",
  wallet_txt: "ウォレット",
  today_txt: "今日",
  week_txt: "今週",
  month_txt: "今月",
  year_txt: "一年中",
  main_indicators_txt: "メインインジケーター",
  sale_price: "セール価格",
  expected_profit: "期待利益",
  order_count: "注文数量",
  goods_click_count: "商品のクリック数",
  supplier_txt: "サプライヤー",
  friends_help_txt: "友達が助けてくれます",
  feedback_txt: "フィードバック",
  feedback: "フィードバック",
  online_service_txt_1: "デポジットカスタマーサービス",
  online_service_txt_2: "ビジネス相談",
  q_and_a_txt: "質問と回答",
  open_now_txt: "開く",
  certification_activation_code: "認定アクティベーション コード",
  plz_write_invite_code: "招待コードを入力してください",
  time_txt: "時間",
  times_txt: "時間",

  wallet_total_assets: "総資産",
  balance_txt: "残高",
  pending_settlement_txt: "決済保留中",
  recharge_txt: "リチャージ",
  withdraw_txt: "引き出し",
  fund_details_txt: "ファンドの詳細",
  no_more_txt: "これ以上はありません",
  no_data_txt: "まだデータがありません",
  detail_txt: "詳細",
  num_txt: "数値",
  money_txt: "金額",
  remark_txt: "備考",
  no_remark_txt: "コメントはありません",
  tip_txt: "ヒント",
  tip_content_txt:
    "現在、通貨の問題により、さまざまな国の販売者が引き受けています",
  need_contact_service: "カスタマーサービスに連絡する必要があります",
  contact_service: "カスタマーサービスに連絡してください",

  feedback_title: "意見をフィードバックしてください",
  feedback_placeholder:
    "セキュリティ上の理由から、個人情報は含めないでください",
  submit_txt: "送信",
  sure_txt: "OK",
  submit_suc_txt: "送信成功",

  loading: "読み込み中...",

  newer_action: "新人アクティビティ",
  newer_traffic_package: "新人サポート トラフィック パッケージ",
  newer_get_millions_of_products: "何百万もの製品を入手",
  newer_get: "受信しました",
  newer_receive: "受信",
  newer_action_ruler: "新人アクティビティ ルール",
  newer_receive_suc: "正常に受信しました",

  help_friends_help: "友達が助けてくれます",
  help_your_friends_stores: "友達を助けてください",
  get_store_product_heating: "店舗製品の加熱を取得",
  help_friends_txt: "友達を助ける",
  help_friends_activity_rules: "友達を助けるアクティビティのルール",
  assistance_details: "サポートの詳細",
  team_list: "チームリスト",
  help_friend_name: "友達の名前を手伝ってください",
  plz_input: "入力してください",
  plz_input_friend_name: "サポートしてくれる友人の名前を入力してください",
  help_suc: "成功を支援",

  online_service: "オンラインカスタマーサービス",
  online_24_hour_service: "24 時間オンライン サポート",
  customer_service: "顧客サービス",
  copy_and_add_id: "LINE IDをコピーして追加",
  plz_input_question_detail: "質問の詳細を入力してください",
  harry_reply_your: "できるだけ早く返信させていただきます",
  click_copy: "クリックしてコピー",
  copy_suc: "コピー成功",
  copy_fail: "コピーに失敗しました",

  overall: "全体的に",
  cooperate: "協力する",
  cancel: "キャンセル",
  supplier_num: "サプライヤー番号",
  no_use_supplier: "利用可能なサプライヤーがありません",
  here_add_supplier: "ここに新しいサプライヤーを追加",
  add_supplier: "サプライヤーを追加",
  plz_input_supplier_num: "サプライヤー番号を入力してください",
  add_suc: "追加に成功しました",
  remove_suc: "正常に削除されました",

  new_products_launched: "新製品が発売されました",
  profit_txt: "利益",
  has_been_added: "棚に上げられました",
  on_display: "ディスプレイ上",
  coming_soon_txt: "現在入手可能です",
  sold_txt: "販売済み",
  removed_txt: "削除されました",

  order_detail_txt: "注文の詳細",
  waiting_express_delivery: "速達を待っています",
  customer_info: "顧客情報",
  order_good: "商品の注文",
  order_num: "注文番号",
  order_date: "注文日",
  order_content: "注文概要",
  purchase_price_txt: "購入価格",
  income_txt: "収入",

  order_txt: "注文",
  pending_payment: "支払いが保留中",
  ready_for_shipment: "出荷準備完了",
  shipped_txt: "発送済み",
  paid_txt: "配信済み",
  good_num: "商品番号",
  pay_and_ship: "支払いと発送",
  pay_suc: "支払いが成功しました",
  no_order: "まだ注文はありません",
  no_order_tips: "ここで注文と返品を追跡できます",
  suer_order: "支払いを確認",
  pay_way: "支払い方法",
  wallet_pay: "ウォレット支払い",
  sure_pay: "支払いを確認",
  paying_txt: "支払い中...",

  manage_showcase: "ショーケースを管理",
  add_new_good: "新しい製品を追加",
  heating_txt: "加熱",
  promotion: "プロモーション",
  promotion_tip: "プロモーションのヒント",
  promotion_tip_txt:
    "現在のポイントが 200 未満のため、商品の支払いができません",
  copy_link: "リンクをコピー",
  scan_qr_code: "QR コードをスキャン",

  about_good: "商品について",
  specification: "仕様",
  product_overview: "製品概要",

  top_tips_1: "Tiktok ストアをオンラインに変換する",
  top_tips_2: "現在は内部招待のみをサポートしています",
  active_place_holeder: "入力してください",

  promotion_01: "プロモーション",
  promotion_02: "プロモーション金額",
  promotion_03: "プロモーション金額を入力してください",
  promotion_04: "プロモーションを確認",
  promotion_05: "プロモーションが成功しました",

  bao_txt: "露出",
  bal_no: "残高が不足しています",

  wallet_type_01: "システム手動リチャージ",
  wallet_type_02: "手動出金",
  Wallet_type_03: "支払い",
  wallet_type_04: "システム控除",
  wallet_type_05: "利益収入",
  wallet_type_06: "支払い控除",
  wallet_type_07: "ディストリビューション消費",
  wallet_type_08: "プロモーション収入",
  wallet_type_09: "ブースト報酬",
  wallet_type_10: "無料で登録",
  wallet_type_11: "売上高報酬",
  wallet_type_12: "出金が拒否されました",

  all_orders: "すべての注文",
  mine_orders: "私の注文",
  mine_services: "私のサービス",
  _tzz: "ジャンプしています...",

  info_01: "名前",
  info_02: "メール",
  info_03: "連絡先情報",
  info_04: "性別",
  info_05: "機密",
  info_06: "男性",
  info_07: "女性",
  info_08: "生年月日(yyyy-MM-dd)",
  info_09: "国",
  info_10: "都市",
  info_11: "カード番号",
  info_12: "カードタイプ",
  info_13: "カード発行会社",
  info_14: "有効期限(yyyy-MM-dd)",
  info_15: "CVV コード",
  info_16: "セキュリティパスワード",
  info_17: "セキュリティパスワードを繰り返します",
  info_18: "セキュリティパスワードを再度入力してください",
  info_19: "2 つのセキュリティ パスワードが矛盾しています",
  info_20: "IDカード/パスポート",
  info_21: "アップロードする画像の種類を選択してください",
  info_22: "IDカード(表裏)",
  info_23: "パスポート",
  info_24: "運転免許証",
  info_25: "居住許可",
  info_26: "健康証明書",
  info_27: "証明写真をアップロードしてください",
  info_28:
    "支払い用の銀行情報を確認するには、カスタマー サービスにお問い合わせください。",

  wl_withdraw_money: "出金金額",
  wl_withdraw_code_201: "出金記録は審査中です。再度送信しないでください",
  wl_withdraw_code_202: "銀行カード情報が正しくありません",
  wl_withdraw_code_400: "出金記録例外",
  wl_withdraw_code_204: "セキュリティパスワードが正しくありません",
  wl_withdraw_code_205: "証拠金残高は出金できません",
  wl_withdraw_code_207:
    "現在のユーザーの星評価は取り消すことができません。星評価を上げてください",
  wl_withdraw_code_208:
    "現在のユーザーの星評価は、今日の引き出しの最大数に達しました",

  wl_withdraw_order_01: "すべて",
  wl_withdraw_order_02: "未審査",
  wl_withdraw_order_03: "監査済み",
  wl_withdraw_order_04: "出金に失敗しました",
  wl_withdraw_order_05: "数値",
  wl_withdraw_order_06: "出金金額",
  wl_withdraw_order_07: "出金方法",
  wl_withdraw_order_08: "出金情報",
  wl_withdraw_order_09: "審査予定",
  wl_withdraw_order_10: "承認済み",
  wl_withdraw_order_11: "完了",
  wl_withdraw_order_12: "キャンセルされました",
  wl_withdraw_order_13: "凍結",

  hl_mine_invite_code: "私の招待コード",
  hl_help_friend_invite_code: "ヘルプフレンド招待コード",
  hl_certification_activation_code: "招待コード",
  hl_plz_write_invite_code: "招待コードを入力してください",
  hl_help_code_0: "招待コードを空にすることはできません",
  hl_help_code_201: "ユーザーは招待コードをバインドしました",
  hl_help_code_202: "招待コードが間違っています",
  hl_help_code_203: "招待コードを自分自身にバインドすることはできません",
  gl_visits: "訪問",

  _in_sp: "商品ランキング",
  _r_k01: "ランキング",
  _r_k02: "商品",
  _r_k03: "熱",

  _zw_ph: "製品ランキング情報はまだありません! ",
  _zf_201: "注文ステータスが異常です",
  _zf_202: "資産残高が不十分です",
  _zf_203: "支払いに失敗しました",

  _withdraw_order_txt: "注文の撤回",

  shop_num: "店舗番号",
  plz_input_shop_num: "ショップ番号を入力してください",
  shop_num_error: "店舗番号が間違っています",
  supplier_num_error: "サプライヤー番号が正しくありません",
  supplier_num_has_bound: "サプライヤー番号はバインドされています",

  sc_day_sale: "毎日の売上",
  sc_week_sale: "週間売上",
  hl_mine_shop: "私のショップ",
  hl_shop_name: "ショップ名",
  hl_input_shop_name: "ショップ名を入力してください",
  hl_add_shop_name: "ショップ名を追加",
  hl_update_shop_name: "ショップ名を変更",
  hl_update_suc: "変更は成功しました",

  hl_rabate_01: "下位レベルの手数料",
  hl_rabate_02: "リベートレコード",
  hl_rabate_03: "リベート率",
  hl_rabate_04: "手数料総額",
  hl_rabate_05: "総売上高",
  hl_rabate_06: "売上金額",
  hl_rabate_07: "リベート金額",
  hl_rabate_08: "登録時間",
  hl_rabate_09: "レベル 1 の支援",
  hl_rabate_10: "レベル 2 の支援",
  hl_rabate_11: "レベル 3 の支援",

  dd_order_01: "注文をキャンセル",
  dd_order_02: "返品",
  dd_order_03: "返品",
  dd_order_04: "返品されました",

  _t_p_qr: "確認",
  _t_p_txt01:
    "注文をキャンセルしますか? 注文のキャンセルは取り消し不可能であり、見つかりません。  ",
  _t_p_txt02: "この注文を返品する必要があるかどうかを確認してください。 ",
  _t_p_txt03: "キャンセルが成功しました",
  _t_p_txt04: "返品リクエストは送信されました",

  showcase_01: "注文する",
  showcase_02: "注文は正常に行われました",
  showcase_03: "友達IDを入力してください",

  goods_price: "価格",

  u_pwd_tips:
    "セキュリティパスワードを変更するには、カスタマーサービスにご連絡ください",

  g_to_be_purchased: "購入予定",
  g_purchasing_and_shipping: "購入と発送",
  g_suer_purchase_order: "購入を確認",

  hl_star_rating_text_1: "1 つ星の販売者",
  hl_star_rating_text_2: "2 つ星の販売者",
  hl_star_rating_text_3: "3 つ星の販売者",
  hl_star_rating_text_4: "4 つ星の販売者",
  hl_star_rating_text_5: "5 つ星の販売者",

  hl_auth_tips: "実名認証が送信され、審査中です...",

  ml_01: "未読",
  ml_02: "読み取り",
  ml_03: "メッセージの内容...",
  ml_04: "送信",
  ml_05: "アップロード中...",

  gl_sale_price: "販売価格",

  wd_fee: "手数料",
  wd_receipt: "実際の到着",
  wd_receipt_way: "既存の支払い方法を選択してください",
  wd_bank_info: "銀行情報",
  wd_bank_info_tip: "形式: 名前-銀行名-口座銀行-カード番号",
  wd_success: "正常に出金できました",
  wd_success_tip:
    "出金申請は正常に完了し、24 時間以内にアカウントに届く予定です",

  g_removed: "削除されました",
  g_sale_ing: "セール中",

  sd_01: "おすすめ商品",
  sd_02: "もっと",
  sd_03: "おすすめストア",
  sd_04: "現在の製品",
  sd_05: "",
  sd_06: "ストアを表示",
  sd_07: "ストア製品",
  sd_08: "アクティビティ ストア",
  sd_09: "違法ストア",

  mc_01: "署名済み",
  mc_02: "支払い済み",
  mc_03: "署名時間",
  mc_04: "支払い時間",
  mc_05: "注文番号",
  mc_06: "署名",
  mc_07: "購入者",
  mc_08: "住所",
  mc_09: "地域",
  mc_10: "購入者に連絡",

  lgi_01: "物流情報",
  lgi_02: "国際商品倉庫と一致",
  lgi_03: "速達配信中",
  lgi_04: "輸送中",
  lgi_05: "配達中",

  np_01: "リスク警告",
  np_02: "システムアナウンス",
  np_03: "読んで理解しました",
  np_04: "ニュース",

  sl_01: "ワンクリックリスト",

  w_pwd_info_01: "出金パスワード",
  w_pwd_info_02: "出金パスワードを繰り返します",
  w_pwd_info_03: "出金パスワードを再度入力してください",
  w_pwd_info_04: "2 つの出金パスワードが一致しません",
  w_pwd_info_05: "出金パスワードが間違っています",
  w_pwd_info_06:
    "出金パスワードを変更するには、カスタマーサービスにご連絡ください",

  l_g_01: "携帯電話番号/メールアドレス",
  l_g_02: "携帯電話番号/メールアドレスを入力してください",
  l_g_03: "認証コード",
  l_g_04: "確認コードを入力してください",
  l_g_05: "送信",
  l_g_06: "ログインパスワード",
  l_g_07: "ログインパスワードを入力してください",
  l_g_08: "ログインパスワードを繰り返す",
  l_g_09: "ログインパスワードをもう一度入力してください",
  l_g_10: "登録",
  l_g_11: "ログイン",
  l_g_12: "すでにアカウントをお持ちですので、ログインしてください",
  l_g_13: "登録に移動",
  l_g_14: "ログアウト",
  l_g_15: "ログアウトを確認しますか?",
  l_g_16: "言語",
  l_g_17: "2 つのログイン パスワードが一致しません",
  l_g_18:
    "ユーザー情報が存在しません。IDが正しく入力されているか確認してください~",
  l_g_19: "ユーザー名",
  l_g_20: "ユーザー名を入力してください",
  l_g_21: "ログインしてコピー",

  h_c_01: "注文利益",
  h_c_02: "支払い済み",
  h_c_03: "発送予定",
  h_c_04: "確認しました",
  h_c_05: "売上高",
  h_c_06: "出店用招待コード",
  h_c_07: "ストアの招待コードを入力してください",
  h_c_08: "友達を招待",

  g_c_01: "私のコレクション",
  g_c_02: "収集に成功しました",
};

export default {
  shop_center_txt: "商店中心",
  score_txt: "分",
  minute_txt: "分",
  wallet_txt: "錢包",
  today_txt: "本日",
  week_txt: "本週",
  month_txt: "本月",
  year_txt: "整年",
  main_indicators_txt: "主要指標",
  sale_price: "銷售價格",
  expected_profit: "預期利潤",
  order_count: "訂單數量",
  goods_click_count: "產品點數",
  supplier_txt: "供應商",
  friends_help_txt: "朋友助力",
  feedback_txt: "回饋",
  feedback: "意見回饋",
  online_service_txt_1: "入金客服",
  online_service_txt_2: "業務諮詢",
  q_and_a_txt: "問答",
  open_now_txt: "開啟",
  certification_activation_code: "認證啟動碼",
  plz_write_invite_code: "請填入邀請碼",
  time_txt: "時",
  times_txt: "時間",

  wallet_total_assets: "總財產",
  balance_txt: "餘額",
  pending_settlement_txt: "待結算",
  recharge_txt: "充值",
  withdraw_txt: "提現",
  fund_details_txt: "資金明細",
  no_more_txt: "沒有更多了",
  no_data_txt: "暫無資料",
  detail_txt: "明細詳情",
  num_txt: "編號",
  money_txt: "金額",
  remark_txt: "備註",
  no_remark_txt: "無備註",
  tip_txt: "提示",
  tip_content_txt: "由於各國商家承銷幣種問題，目前",
  need_contact_service: "需聯絡客服",
  contact_service: "聯絡客服",

  feedback_title: "回饋你的意見",
  feedback_placeholder: "出於安全考慮，請勿包含個人資訊",
  submit_txt: "提交",
  sure_txt: "確定",
  submit_suc_txt: "提交成功",

  loading: "載入中...",

  newer_action: "新人活動",
  newer_traffic_package: "新人扶持流量包",
  newer_get_millions_of_products: "領取百萬商品",
  newer_get: "已領取",
  newer_receive: "領取",
  newer_action_ruler: "新人活動規則",
  newer_receive_suc: "領取成功",

  help_friends_help: "好友助力",
  help_your_friends_stores: "幫助您的好友助力",
  get_store_product_heating: "取得商店商品加熱",
  help_friends_txt: "助力朋友",
  help_friends_activity_rules: "助力好友活動規則",
  assistance_details: "助力明細",
  team_list: "團隊列表",
  help_friend_name: "助力好友名",
  plz_input: "請輸入",
  plz_input_friend_name: "請輸入助力好友名",
  help_suc: "助力成功",

  online_service: "線上客服",
  online_24_hour_service: "24小時線上支援",
  customer_service: "客戶服務",
  copy_and_add_id: "複製並新增LINE ID",
  plz_input_question_detail: "請輸入你的問題的詳細資料",
  harry_reply_your: "我們會盡快回覆你",
  click_copy: "點選複製",
  copy_suc: "複製成功",
  copy_fail: "複製失敗",

  overall: "整體",
  cooperate: "合作",
  cancel: "取消",
  supplier_num: "供貨商編號",
  no_use_supplier: "沒有可用的供應商",
  here_add_supplier: "在這裡新增供應商",
  add_supplier: "新增供貨商",
  plz_input_supplier_num: "請輸入供貨商編號",
  add_suc: "新增成功",
  remove_suc: "下架成功",

  new_products_launched: "上架新商品",
  profit_txt: "利潤",
  has_been_added: "已上架",
  on_display: "可上架",
  coming_soon_txt: "上架中",
  sold_txt: "已售出",
  removed_txt: "已下架",

  order_detail_txt: "訂單詳情",
  waiting_express_delivery: "等待快遞攬收",
  customer_info: "客戶資訊",
  order_good: "訂單商品",
  order_num: "訂單號碼",
  order_date: "訂單日期",
  order_content: "訂單摘要",
  purchase_price_txt: "進價",
  income_txt: "收入",

  order_txt: "訂單",
  pending_payment: "待付款",
  ready_for_shipment: "準備出貨",
  shipped_txt: "已出貨",
  paid_txt: "已交付",
  good_num: "商品編號",
  pay_and_ship: "付款與發貨",
  pay_suc: "支付成功",
  no_order: "暫無訂單",
  no_order_tips: "你可以在這裡追蹤你的訂單和退貨",
  suer_order: "確認付款",
  pay_way: "付款方式",
  wallet_pay: "錢包支付",
  sure_pay: "確認付款",
  paying_txt: "付款中...",

  manage_showcase: "管理櫥窗",
  add_new_good: "新增商品",
  heating_txt: "加熱",
  promotion: "推廣",
  promotion_tip: "推廣提示",
  promotion_tip_txt: "您目前的積分低於200，您無法為您的產品付款",
  copy_link: "複製連結",
  scan_qr_code: "掃描二維碼",

  about_good: "關於商品",
  specification: "規格",
  product_overview: "產品概覽",

  top_tips_1: "抖音店舖線上轉線上",
  top_tips_2: "目前僅支援內部邀請",
  active_place_holeder: "請輸入",

  promotion_01: "推廣",
  promotion_02: "推廣金額",
  promotion_03: "請輸入推廣金額",
  promotion_04: "確認推廣",
  promotion_05: "推廣成功",

  bao_txt: "曝光率",
  bal_no: "餘額不足",

  wallet_type_01: "系統人工儲值",
  wallet_type_02: "人工提現",
  wallet_type_03: "支付貨款",
  wallet_type_04: "系統扣款",
  wallet_type_05: "利潤收入",
  wallet_type_06: "支付扣款",
  wallet_type_07: "投放消耗",
  wallet_type_08: "推廣收益",
  wallet_type_09: "助力獎勵",
  wallet_type_10: "註冊贈送",
  wallet_type_11: "營業額獎勵",
  wallet_type_12: "提現駁回",

  all_orders: "全部訂單",
  mine_orders: "我的訂單",
  mine_services: "我的服務",
  _tzz: "跳轉中...",

  info_01: "姓名",
  info_02: "郵箱",
  info_03: "聯絡方式",
  info_04: "性別",
  info_05: "保密",
  info_06: "男",
  info_07: "女",
  info_08: "出生日期(yyyy-MM-dd)",
  info_09: "國家",
  info_10: "城市",
  info_11: "卡號",
  info_12: "卡片類型",
  info_13: "發卡機構",
  info_14: "到期日(yyyy-MM-dd)",
  info_15: "CVV碼",
  info_16: "安全密碼",
  info_17: "重複安全密碼",
  info_18: "請再輸入安全密碼",
  info_19: "2次安全密碼不一致",
  info_20: "身分證/護照",
  info_21: "選擇上傳圖片類型",
  info_22: "身分證(正反面)",
  info_23: "護照",
  info_24: "駕駛證",
  info_25: "居住證明",
  info_26: "健康證明",
  info_27: "請上傳證件照片",
  info_28: "收款的銀行訊息，請聯絡客服進行核對無誤",

  wl_withdraw_money: "提現金額",
  wl_withdraw_code_201: "提現記錄審核中，請勿重複提交",
  wl_withdraw_code_202: "銀行卡資料有誤",
  wl_withdraw_code_400: "提現記錄異常",
  wl_withdraw_code_204: "安全密碼錯誤",
  wl_withdraw_code_205: "保證金餘額不能提領",
  wl_withdraw_code_207: "目前使用者星級無法提現​​，請提升星級",
  wl_withdraw_code_208: "目前使用者星級今日提現已達最大提現次數",

  wl_withdraw_order_01: "全部",
  wl_withdraw_order_02: "未審核",
  wl_withdraw_order_03: "已審核",
  wl_withdraw_order_04: "提現失敗",
  wl_withdraw_order_05: "編號",
  wl_withdraw_order_06: "提現金金額",
  wl_withdraw_order_07: "提現方式",
  wl_withdraw_order_08: "提現訊息",
  wl_withdraw_order_09: "待審核",
  wl_withdraw_order_10: "審核通過",
  wl_withdraw_order_11: "已完成",
  wl_withdraw_order_12: "已取消",
  wl_withdraw_order_13: "已凍結",

  hl_mine_invite_code: "我的邀請碼",
  hl_help_friend_invite_code: "協助好友邀請碼",
  hl_certification_activation_code: "邀請碼",
  hl_plz_write_invite_code: "請輸入邀請碼",
  hl_help_code_0: "邀請碼不能為空",
  hl_help_code_201: "使用者已綁定邀請碼",
  hl_help_code_202: "邀請碼有誤",
  hl_help_code_203: "邀請碼不能綁定自已",
  gl_visits: "訪問量",

  _in_sp: "商品排行",
  _r_k01: "排名",
  _r_k02: "商品",
  _r_k03: "熱度",

  _zw_ph: "暫無商品排行資訊！ ",
  _zf_201: "訂單狀態異常",
  _zf_202: "資產餘額不足",
  _zf_203: "支付失敗",

  _withdraw_order_txt: "提現訂單",

  shop_num: "商店編號",
  plz_input_shop_num: "請輸入店舖編號",
  shop_num_error: "店舖編號有誤",
  supplier_num_error: "供貨商編號有誤",
  supplier_num_has_bound: "供貨商編號已綁定",

  sc_day_sale: "日銷售",
  sc_week_sale: "週銷售",
  hl_mine_shop: "我的店鋪",
  hl_shop_name: "店鋪名稱",
  hl_input_shop_name: "請輸入店鋪名稱",
  hl_add_shop_name: "新增店鋪名稱",
  hl_update_shop_name: "修改店鋪名稱",
  hl_update_suc: "修改成功",

  hl_rabate_01: "下級返傭",
  hl_rabate_02: "返傭紀錄",
  hl_rabate_03: "返傭比例",
  hl_rabate_04: "返傭總額",
  hl_rabate_05: "銷售總額",
  hl_rabate_06: "銷售金額",
  hl_rabate_07: "返佣金額",
  hl_rabate_08: "註冊時間",
  hl_rabate_09: "一級助力",
  hl_rabate_10: "二級助力",
  hl_rabate_11: "三級助力",

  dd_order_01: "取消訂單",
  dd_order_02: "退貨",
  dd_order_03: "退貨中",
  dd_order_04: "已退貨",

  _t_p_qr: "確認",
  _t_p_txt01: "是否取消訂單，取消訂單不可撤回且無法找到！ ！ ！ ",
  _t_p_txt02: "請確認該訂單是否需要進行退貨處理！ ",
  _t_p_txt03: "取消成功",
  _t_p_txt04: "退貨申請已提交",

  showcase_01: "下單",
  showcase_02: "下單成功",
  showcase_03: "請輸入好友ID",

  goods_price: "價格",

  u_pwd_tips: "修改安全密碼請聯絡客服",

  g_to_be_purchased: "待採購",
  g_purchasing_and_shipping: "採購並發貨",
  g_suer_purchase_order: "確認採購",

  hl_star_rating_text_1: "一星商家",
  hl_star_rating_text_2: "二星商家",
  hl_star_rating_text_3: "三星商家",
  hl_star_rating_text_4: "四星商家",
  hl_star_rating_text_5: "五星商家",

  hl_auth_tips: "實名認證已提交，正在審核中...",

  ml_01: "未讀",
  ml_02: "已讀",
  ml_03: "訊息內容...",
  ml_04: "發送",
  ml_05: "上傳中...",

  gl_sale_price: "售價",

  wd_fee: "手續費",
  wd_receipt: "實際到帳",
  wd_receipt_way: "選擇已有收款方式",
  wd_bank_info: "銀行資訊",
  wd_bank_info_tip: "格式：姓名-銀行名稱-開戶行-卡號",
  wd_success: "提現成功",
  wd_success_tip: "您的提現申請已成功，預計24小時內到達您的帳戶",

  g_removed: "下架",
  g_sale_ing: "在售中",

  sd_01: "推薦產品",
  sd_02: "更多",
  sd_03: "推薦店舖",
  sd_04: "目前產品",
  sd_05: "個",
  sd_06: "查看店家",
  sd_07: "店舖產品",
  sd_08: "活動店舖",
  sd_09: "違規店舖",

  mc_01: "已簽收",
  mc_02: "已支付",
  mc_03: "簽收時間",
  mc_04: "支付時間",
  mc_05: "訂單編號",
  mc_06: "簽收人",
  mc_07: "購買人",
  mc_08: "地址",
  mc_09: "地區",
  mc_10: "聯絡買家",

  lgi_01: "物流資訊",
  lgi_02: "匹配國際貨品倉庫",
  lgi_03: "快遞收攬中",
  lgi_04: "運輸中",
  lgi_05: "配送中",

  np_01: "風險提示",
  np_02: "系統公告",
  np_03: "我已閱讀,並知道",
  np_04: "新聞資訊",

  sl_01: "一鍵上架",

  w_pwd_info_01: "提現密碼",
  w_pwd_info_02: "重複提現密碼",
  w_pwd_info_03: "請再次輸入提現密碼",
  w_pwd_info_04: "2次提現密碼不一致",
  w_pwd_info_05: "提現密碼錯誤",
  w_pwd_info_06: "修改提現密碼請聯絡客服",

  l_g_01: "手機號碼/信箱",
  l_g_02: "請輸入手機號碼/信箱",
  l_g_03: "驗證碼",
  l_g_04: "請輸入驗證碼",
  l_g_05: "發送",
  l_g_06: "登入密碼",
  l_g_07: "請輸入登入密碼",
  l_g_08: "重複登入密碼",
  l_g_09: "請再輸入登入密碼",
  l_g_10: "註冊",
  l_g_11: "登入",
  l_g_12: "已有帳號，去登入",
  l_g_13: "去註冊",
  l_g_14: "退出登入",
  l_g_15: "確認已登出?",
  l_g_16: "語言",
  l_g_17: "2次登入密碼不一致",
  l_g_18: "使用者資訊不存在,請檢查ID是否輸入正確~",
  l_g_19: "使用者名稱",
  l_g_20: "請輸入使用者名稱",
  l_g_21: "登入並複製",

  h_c_01: "訂單收益",
  h_c_02: "已付款",
  h_c_03: "待出貨",
  h_c_04: "已確認",
  h_c_05: "銷售額",
  h_c_06: "開店邀請碼",
  h_c_07: "請輸入開店邀請碼",
  h_c_08: "邀請好友",

  g_c_01: "我的收藏",
  g_c_02: "收藏成功",
};
